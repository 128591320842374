<!-- 商品管理右边区域 -->
<template>
  <div>
    <div style="margin-bottom: 5px">
      <el-input
        v-model="name"
        placeholder="请输入剧本名"
        suffix-icon="el-icon-search"
        style="width: 200px"
        @keyup.enter.native="loadPost"
      ></el-input>
      <el-button type="primary" style="margin-left: 5px" @click="loadPost"
        >查询</el-button
      >
      <el-button type="success" @click="resetParam">重置</el-button>

      <el-button type="primary" style="margin-left: 5px" @click="add()"
        >添加</el-button
      >
    </div>
    <el-table
      :data="tableData"
      :header-cell-style="{ background: '#f2f5fc', color: '#555555' }"
      border
    >
      <el-table-column prop="id" type="index" label="ID" width="60">
      </el-table-column>
      <el-table-column prop="name" label="剧本名" width="150">
      </el-table-column>
      <el-table-column prop="price" label="价格" width="80"> </el-table-column>
      <el-table-column prop="image" label="剧本封面">
        <template slot-scope="scope">
          <img
            style="width: 50px; height: 50px"
            :src="$httpUrl + '/image/' + scope.row.image"
            alt=""
          />
        </template>
      </el-table-column>
      <el-table-column prop="operate" label="" width="180">
        <template slot-scope="scope">
          <el-button size="small" type="success" @click="mod(scope.row)"
            >修改</el-button
          >
          <el-popconfirm
            title="确定删除吗？"
            @confirm="del(scope.row.id)"
            style="margin-left: 5px"
          >
            <el-button slot="reference" size="small" type="danger"
              >删除</el-button
            >
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageNum"
      :page-sizes="[5, 10, 20, 30]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>

    <el-dialog
      title="提示"
      width="80%"
      :visible.sync="centerDialogVisible"
      center
      :close-on-click-modal="false"
    >
      <el-form ref="form" :rules="rules" :model="drama2" label-width="100px">
        <el-form-item label="名称" prop="name">
          <el-col :span="10">
            <el-input
              v-model="drama2.name"
              style="width: 300px"
              disabled
            ></el-input>
          </el-col>
        </el-form-item>
        <div style="display: flex; flex-flow: wrap">
          <el-form-item label="价格" prop="price"
            ><el-input v-model="drama2.price"></el-input
          ></el-form-item>
          <el-form-item label="店长推荐" prop="recommend">
            <!-- `checked` 为 true 或 false -->
            <el-checkbox v-model="drama2.recommend"></el-checkbox>
          </el-form-item>
          <el-form-item label="场数" prop="hot">
            <el-col :span="10" style="width: 100px">
              <el-input v-model="drama2.hot"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item label="新本" prop="newly">
            <el-checkbox v-model="drama2.newly"></el-checkbox>
          </el-form-item>

          <el-form-item label="男生数" prop="boy">
            <el-input
              v-model="drama2.boy"
              style="width: 300px"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="女生数" prop="girl">
            <el-input
              v-model="drama2.girl"
              style="width: 300px"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="时长" prop="duration">
            <el-input
              v-model="drama2.duration"
              style="width: 300px"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="难度" prop="level">
            <el-select v-model="drama2.level" placeholder="请选择难度" disabled>
              <el-option label="新手" value="新手"></el-option>
              <el-option label="进阶" value="进阶"></el-option>
              <el-option label="硬核" value="硬核"></el-option>
            </el-select>
          </el-form-item>
        </div>
        <el-form-item label="剧本封面" prop="image">
          <el-col :span="20">
            <img
              :src="$httpUrl + '/image/' + drama2.image"
              style="width: 150px; height: 150px"
            />
          </el-col>
        </el-form-item>
        <el-form-item label="人物介绍" prop="images" disabled>
          <el-col :span="20">
            <img
              v-for="item in drama2.images"
              :src="item"
              :key="item"
              style="width: 150px; height: 150px; margin-right: 10px"
            />
          </el-col>
        </el-form-item>
        <el-form-item label="图片名称" prop="names" disabled>
          <el-col :span="20">
            <el-input
              v-for="(item, index) in drama2.names"
              :key="index"
              v-model="drama2.names[index]"
              style="width: 120px; margin-left: 11px; margin-top: 11px"
              disabled
            ></el-input>
          </el-col>
        </el-form-item>
        <div style="display: flex; flex-flow: wrap">
          <el-form-item label="剧本介绍" prop="context">
            <el-input
              type="textarea"
              style="width: 800px"
              v-model="drama2.context"
            ></el-input>
          </el-form-item>
          <el-form-item label="规格" prop="specs">
            <el-col :span="10" style="width: 200px">
              <el-select v-model="drama2.specs" multiple placeholder="请选择">
                <el-option
                  v-for="item in specs"
                  :key="item.title"
                  :label="item.title"
                  :value="item.title"
                  :class="[item.selected ? 'selected' : '']"
                  disabled
                >
                </el-option>
              </el-select>
            </el-col>
          </el-form-item>
          <el-form-item label="适合人数" prop="number">
            <el-col :span="10" style="width: 70px">
              <el-input v-model="drama2.number" disabled></el-input>
            </el-col>
          </el-form-item>
          <el-form-item label="题材" prop="type">
            <el-col :span="10" style="width: 200px">
              <el-select
                v-model="drama2.type"
                multiple
                placeholder="请选择"
                disabled
              >
                <el-option
                  v-for="item in type"
                  :key="item.title"
                  :label="item.title"
                  :value="item.title"
                  :class="[item.selected ? 'selected' : '']"
                  disabled
                >
                </el-option>
              </el-select>
            </el-col>
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>

        <el-button type="primary" @click="update">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="添加剧本"
      width="80%"
      :visible.sync="dialog2"
      center
      :close-on-click-modal="false"
    >
      <el-form ref="form1" :rules="rules" :model="drama" label-width="100px">
        <el-form-item label="剧本列表">
          <el-select v-model="d_id" placeholder="请选择剧本" clearable>
            <el-option
              v-for="item in dramas"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <div v-if="d_id > 0">
          <el-form-item label="名称" prop="name">
            <el-col :span="10">
              <el-input
                v-model="drama.name"
                style="width: 300px"
                disabled
              ></el-input>
            </el-col>
          </el-form-item>
          <div style="display: flex; flex-flow: wrap">
            <el-form-item label="价格" prop="price">
              <el-input v-model="drama.price" style="width: 300px"></el-input>
            </el-form-item>
            <el-form-item label="店长推荐" prop="recommend">
              <el-checkbox v-model="drama.recommend"></el-checkbox>
            </el-form-item>
            <el-form-item label="场数" prop="hot">
              <el-col :span="10" style="width: 100px">
                <el-input v-model="drama.hot"></el-input>
              </el-col>
            </el-form-item>
            <el-form-item label="新本" prop="newly">
              <el-checkbox v-model="drama.newly"></el-checkbox>
            </el-form-item>
            <el-form-item label="男生数" prop="boy">
              <el-input
                v-model="drama.boy"
                style="width: 300px"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="女生数" prop="girl">
              <el-input
                v-model="drama.girl"
                style="width: 300px"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="时长" prop="duration">
              <el-input
                v-model="drama.duration"
                style="width: 300px"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="难度" prop="level">
              <el-select
                v-model="drama.level"
                placeholder="请选择难度"
                disabled
              >
                <el-option label="新手" value="新手"></el-option>
                <el-option label="进阶" value="进阶"></el-option>
                <el-option label="硬核" value="硬核"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <el-form-item label="剧本封面" prop="image">
            <el-col :span="20">
              <img
                :src="$httpUrl + '/image/' + drama.image"
                style="width: 120px; height: 120px; margin-left: 11px"
              />
            </el-col>
          </el-form-item>
          <el-form-item label="人物介绍" prop="images" disabled>
            <el-col :span="20">
              <img
                v-for="item in images"
                :src="item"
                :key="item"
                style="
                  width: 120px;
                  height: 120px;
                  margin-left: 11px;
                  margin-top: 11px;
                "
              />
            </el-col>
          </el-form-item>
          <el-form-item label="图片名称" prop="names" disabled>
            <el-col :span="20">
              <el-input
                v-for="item in names"
                :key="item"
                :value="item"
                style="width: 120px; margin-left: 11px; margin-top: 11px"
                disabled
              ></el-input>
            </el-col>
          </el-form-item>
          <div style="display: flex; flex-flow: wrap">
            <el-form-item label="剧本介绍" prop="context">
              <el-input
                type="textarea"
                style="width: 800px"
                v-model="drama.context"
              ></el-input>
            </el-form-item>
            <el-form-item label="规格" prop="specs">
              <el-col :span="10" style="width: 200px">
                <el-select v-model="drama.specs" multiple placeholder="请选择">
                  <el-option
                    v-for="item in specs"
                    :key="item.title"
                    :label="item.title"
                    :value="item.title"
                    :class="[item.selected ? 'selected' : '']"
                    disabled
                  >
                  </el-option>
                </el-select>
              </el-col>
            </el-form-item>
            <el-form-item label="适合人数" prop="number">
              <el-col :span="10" style="width: 70px">
                <el-input v-model="drama.number" disabled></el-input>
              </el-col>
            </el-form-item>
            <el-form-item label="题材" prop="type">
              <el-col :span="10" style="width: 200px">
                <el-select
                  v-model="drama.type"
                  multiple
                  placeholder="请选择"
                  disabled
                >
                  <el-option
                    v-for="item in type"
                    :key="item.title"
                    :label="item.title"
                    :value="item.title"
                    :class="[item.selected ? 'selected' : '']"
                    disabled
                  >
                  </el-option>
                </el-select>
              </el-col>
            </el-form-item>
          </div>
        </div>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialog2 = false">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import imgUploader from "../imgUploader/imgUploader.vue";
export default {
  name: "GoodstypeManage",
  components: {
    // imgUploader
  },
  data() {
    return {
      tableData: [
        {
          id: "1",
          name: "苍天饶过谁",
          price: "$199",
          boy: "3",
          girl: "3",
          duration: "3-5",
          level: "新手",
          image: "1677215830973.jpeg",
          puttime: "2023-03-08 20:11:59",
          grade: "8.7",
          peoples: [],
          context: "ghiosdfjlakhna",
          specs: "盒装,独家,实景",
          number: 5,
          type: "情感，恐怖，推理",
          recommend: "1",
          hot: "2",
          newly: "1",
        },
      ],
      pageSize: 10,
      pageNum: 1,
      total: 0,
      name: "",
      // title: "",
      specs: [],
      type: [],
      images: [],
      names: [],
      centerDialogVisible: false,
      dramas: [],
      drama: {},
      drama2: {
        id: "",
        name: "",
        price: 0,
        boy: 0,
        girl: 0,
        duration: "",
        level: "",
        fenmian: "",
        puttime: "",
        images: [],
        names: [],
        grade: 0,
        context: "",
        // specs: [],
        number: 0,
        // type: [],
        recommend: "",
        hot: 0,
        newly: "",
      },
      d_id: "",
      d_name: "",
      dialog2: false,
      form: {
        id: "",
        name: "",
        price: 0,
        boy: 0,
        girl: 0,
        duration: "",
        level: "",
        fenmian: "",
        puttime: "",
        images: [],
        names: [],
        grade: 0,
        context: "",
        // specs: [],
        number: 0,
        // type: [],
        recommend: "",
        hot: 0,
        newly: "",
      },
      rules: {
        // name: [{ required: true, message: "请输入剧本名", trigger: "blur" }],
        price: [
          { required: true, message: "请输入价格", trigger: "blur" },
          {
            pattern: /^[0-9]*$/,
            message: "请输入纯数字",
            trigger: "change",
          },
        ],
        hot: [
          { required: true, message: "请输入场数", trigger: "blur" },
          {
            pattern: /^[0-9]*$/,
            message: "请输入纯数字",
            trigger: "change",
          },
        ],
        // girl: [{ required: true, message: "请输入女生数", trigger: "blur" }],
        // duration: [
        //   {
        //     required: true,
        //     message: "请输入游戏时长（5-6小时）",
        //     trigger: "blur",
        //   },
        // ],
        // level: [{ required: true, message: "请输入剧本难度", trigger: "blur" }],
        // fenmian: [{ required: true, message: "请输入", trigger: "blur" }],
        // images: [{ required: true, message: "请输入", trigger: "blur" }],
        // names: [{ required: true, message: "请输入", trigger: "blur" }],
        number: [
          { required: true, message: "请输入适合人数", trigger: "blur" },
        ],
      },
    };
  },
  watch: {
    // 4、 监听对象中的 某个属性
    async d_id(newVal, oldVal) {
      if (this.d_id > 0) {
        let a = this.dramas.filter((x) => {
          return x.id == newVal;
        });
        this.drama = a[0];
        // this.drama2 = a[0];
        let imagelist = [];
        let namelist = [];
        this.drama.peoples.forEach((element) => {
          imagelist.push(this.$httpUrl + "/image/" + element.image);
          namelist.push(element.name);
        });
        this.d_name = this.drama.name;
        this.images = imagelist;
        this.names = namelist;
        // this.drama2.images = imagelist;
        // this.drama2.names = namelist;
        // console.log(this.drama2);
      }
    },
  },
  methods: {
    // uploadimgone(v) {
    //   this.drama.images = this.$refs.pic2.imgUrl;
    // },
    update() {
      console.log(this.form);
      // let flag = false;
      // if (!flag) {
      //   return;
      // }
      // 是否 有未填项
      if (this.drama2.price <= 0 || this.drama2.hot < 0) {
        this.$message({
          message: "价格或场数有误（价格最小为1）  ",
          type: "warning",
        });
        return;
      }
      let a = {
        id: this.drama2.id,
        d_id: this.d_id,
        d_name: this.d_name,
        s_name: this.$store.getters.getMenu.s_name,
        s_id: this.$store.getters.getMenu.s_id,
        u_name: this.$store.getters.getMenu.u_name,
        u_id: this.$store.getters.getMenu.u_id,
        price: this.drama2.price,
        recommend: this.drama2.recommend,
        hot: this.drama2.hot,
        newly: this.drama2.newly,
      };

      this.$axios
        .post(this.$httpUrl + "/shopdrama/addUpdate", a)
        .then((res) => res.data)
        .then((res) => {
          if (res.code == 200) {
            this.$message({
              message: "操作成功！",
              type: "success",
            });
            this.centerDialogVisible = false;
          } else {
            this.$message({
              message: res.msg,
              type: "error",
            });
          }
          this.loadPost();
        });
    },
    save() {
      console.log(this.form);
      // let flag = false;
      // if (!flag) {
      //   return;
      // }
      // 是否 有未填项

      if (this.d_id <= 0) {
        this.$message({
          message: "请选择要添加的剧本",
          type: "warning",
        });
        return;
      }
      if (this.drama.price <= 0 || this.drama.hot < 0) {
        this.$message({
          message: "价格或场数有误（价格最小为1）  ",
          type: "warning",
        });
        return;
      }
      let a = {
        d_id: this.d_id,
        d_name: this.d_name,
        s_name: this.$store.getters.getMenu.s_name,
        s_id: this.$store.getters.getMenu.s_id,
        u_name: this.$store.getters.getMenu.u_name,
        u_id: this.$store.getters.getMenu.u_id,
        price: this.drama.price,
        recommend: this.drama.recommend,
        hot: this.drama.hot,
        newly: this.drama.newly,
      };

      this.$axios
        .post(this.$httpUrl + "/shopdrama/addUpdate", a)
        .then((res) => res.data)
        .then((res) => {
          if (res.code == 200) {
            this.$message({
              message: "操作成功！",
              type: "success",
            });
            this.dialog2 = false;
          } else {
            this.$message({
              message: res.msg,
              type: "error",
            });
          }
          this.loadPost();
        });
    },
    // 删除商品
    del(id) {
      this.$axios
        .get(this.$httpUrl + "/shopdrama/remove/" + id)
        .then((res) => res.data)
        .then((res) => {
          if (res.code == 200) {
            this.$message({
              message: "删除成功！",
              type: "success",
            });
            this.loadPost();
          } else {
            this.$message({
              message: "删除失败！",
              type: "error",
            });
          }
        });
    },
    // 编辑商品
    mod(row) {
      console.log(row);
      // let flag = false;
      // if (!flag) {
      //   return;
      // }
      // 封装 图片的前缀
      let images = [];
      let names = [];
      row.peoples.forEach((element) => {
        images.push(this.$httpUrl + "/image/" + element.image);
        names.push(element.name);
      });
      console.log(images);
      console.log(names);
      this.drama2.images = images;
      this.drama2.names = names;
      (this.drama2.id = row.id),
        (this.drama2.name = row.name),
        (this.drama2.price = row.price),
        (this.drama2.boy = row.boy),
        (this.drama2.girl = row.girl),
        (this.drama2.duration = row.duration),
        (this.drama2.level = row.level),
        (this.drama2.type = row.type),
        (this.drama2.image = row.image),
        (this.drama2.puttime = ""),
        (this.drama2.grade = row.grade),
        (this.drama2.context = row.context),
        (this.drama2.specs = row.specs),
        (this.drama2.number = row.number),
        (this.drama2.recommend = row.recommend),
        (this.drama2.hot = row.hot),
        (this.drama2.newly = row.newly);
      console.log(this.drama2.images);
      this.centerDialogVisible = true;
    },
    // 新增商品
    add() {
      this.dialog2 = true;
      this.d_id = "";
      this.drama = {};
      this.form = {
        id: "",
        name: "",
        price: 0,
        boy: 0,
        girl: 0,
        duration: "",
        level: "",
        fenmian: [],
        images: [],
        names: [],
        puttime: "",
        grade: 0,
        context: "",
        specs: [],
        number: 0,
        type: [],
        recommend: "",
        hot: 0,
        newly: "",
      };
      this.names = [];
      // 清空 默认选中
      let types = [];
      this.type.forEach((x) => {
        let out_x = x;
        out_x.selected = false;
        types.push(out_x);
      });
      this.type = types;
      let specs = [];
      this.specs.forEach((x) => {
        let out_x = x;
        out_x.selected = false;
        specs.push(out_x);
      });
      this.specs = specs;
    },

    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageNum = 1;
      this.pageSize = val;
      this.loadPost();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pageNum = val;
      this.loadPost();
    },
    resetParam() {
      this.name = "";
      this.title = "";
    },
    // 查询商品
    loadPost() {
      this.tableData = [];
      let a = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        param: {
          name: this.name,
          s_id: this.$store.getters.getMenu.s_id,
        },
      };
      this.$axios
        .post(this.$httpUrl + "/shopdrama/pagelist", a)
        .then((res) => res.data)
        .then((res) => {
          if (res.code == 200) {
            this.tableData = res.data;
            console.log(this.tableData);
          }
        });
    },
    loadDrama() {
      let a = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        param: {
          name: this.name,
        },
      };
      this.$axios
        .post(this.$httpUrl + "/drama/pagelist", a)
        .then((res) => res.data)
        .then((res) => {
          if (res.code == 200) {
            this.dramas = res.data;
            console.log(this.dramas);
          }
        });
    },
  },
  beforeMount() {
    this.loadPost();
    this.loadDrama();
  },
};
</script>

<style scoped>
</style>